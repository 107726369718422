@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;600;700;800;900&family=Maven+Pro:wght@400;500;600;700;800;900&display=swap');

/*==BASIC-SETUP==*/
body {
font-family: 'Maven Pro', sans-serif;
  font-size: 20px;
  font-weight: 400;
  color: #000000;
  background: #ffffff;
}

a,
a:hover {
  text-decoration: none;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
label {
  margin-bottom: 0;
}

/* Scroll top START */

.progress-wrap {
	position: fixed;
	right: 30px;
	bottom: 30px;
	height: 46px;
	width: 46px;
	cursor: pointer;
	display: block;
	border-radius: 50px;
	box-shadow: inset  0 0 0 2px rgba(0,0,0,0.1);
	z-index: 10000;
	opacity: 0;
	visibility: hidden;
	transform: translateY(15px);
	-webkit-transition: all 200ms linear;
    transition: all 200ms linear;
}
.progress-wrap.active-progress {
	opacity: 1;
	visibility: visible;
	transform: translateY(0);
}
.progress-wrap::after {
	position: absolute;
	font-family: 'Font Awesome 6 Free';
	font-weight: 900;
	content: "\f106";
	text-align: center;
	line-height: 46px;
	font-size: 24px;
	color: #EE3268; 
	left: 0;
	top: 0;
	height: 46px;
	width: 46px;
	cursor: pointer;
	display: block;
	z-index: 1;
	-webkit-transition: all 200ms linear;
    transition: all 200ms linear;
}

.progress-wrap::before {
	position: absolute;
  font-family: 'Font Awesome 6 Free';
  font-weight: 900;
  content: "\f106";
	text-align: center;
	line-height: 46px;
	font-size: 24px;
	opacity: 0;
	color: #000;
	-webkit-text-fill-color: transparent;
	left: 0;
	top: 0;
	height: 46px;
	width: 46px;
	cursor: pointer;
	display: block;
	z-index: 2;
	-webkit-transition: all 200ms linear;
    transition: all 200ms linear;
}
.progress-wrap:hover::before {
	opacity: 1;
}
.progress-wrap svg path { 
	fill: none; 
}
.progress-wrap svg.progress-circle path {
	stroke: #EE3268; 
	stroke-width: 4;
	box-sizing:border-box;
	-webkit-transition: all 200ms linear;
    transition: all 200ms linear;
}

/* Scroll top END */

.header-area {
	padding: 15px 0;
	background-image: url('../../public/images/banner-bg.svg');
	background-size: 100% 100%;
	background-position: center;
	background-repeat: no-repeat;
}

.header-inner {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.nav-items ul {
	display: flex;
	align-items: center;
}

.nav-items ul li {
	padding-right: 56px;
}

.nav-items ul li a {
	font-family: 'Inter', sans-serif;
	font-size: 20px;
	font-weight: 700;
	color: #EE3268; 
	display: inline-block;
	transition: 0.2s;
	text-transform: lowercase;
}

.nav-items ul li .active-nav {
	color: #000;
}

.nav-items ul li a:hover {
	color:#000;
}

.header-inner .nav-items {
	margin-top: -50px;
}

.menu-bar {
	cursor: pointer;
	display: none;
}

.menu-bar a i {
	font-size: 28px;
	color: #EE3268;
}

.offcanvas {
	max-width: 300px;
}

.offcanvas-body {
	padding: 0;
}

.offcanvas-body .nav-items {
	display: block;
}

.offcanvas-body .nav-items ul {
	display: block;
}

.offcanvas-body .nav-items ul li {
	padding: 0;
}

.offcanvas-body .nav-items ul li a {
	display: block;
	padding: 10px 20px;
}

.offcanvas-body .nav-items ul li a:hover {
	background-color: #EE3268;
	color: #000;
}

.btn-close {
	opacity: 1;
	width: 0em;
  	height: 0em;
}

.btn-close:focus {
	outline: none;
	box-shadow: none;
}

/* HERO AREA START  */

.hero-area {
	padding: 110px 0;
}

.hero-left {
	max-width: 840px;
}

.hero-title h2 {
	color: #000;
	font-size: 36px;
	font-weight: 400; 
	margin: 0;
	line-height: normal;
}

.color-1 {
	color: #EE3268;
	font-weight: 600;
}

.hero-title h2 .color-2 {
	font-weight: 600;
}

.color-3 {
	font-weight: 600;
	color: #01E3D3; 
}

.countdown-area {
	padding: 100px 0 90px;
}

.countdown-title h3 {
	color: #EE3268;
	font-size: 48px;
	font-weight: 600;
	line-height: normal; 
	margin: 0;
}

.countdown-inner {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	padding: 20px 0 0;
}

.countdown-box {
	border-radius: 25px;
	background: #01E3D3; 
	padding: 8px 8px 12px;
	text-align: center;
	width: 100px;
	height: 100px;
}

.countdown-box h3 {
	font-family: 'Inter', sans-serif;
	color: #FFF;
	font-size: 48px;
	font-weight: 700;
	line-height: normal; 
	margin: 0;
}

.countdown-box h4 {
	font-family: 'Inter', sans-serif;
	color: #000; 
	font-size: 24px;
	font-weight: 700;
	line-height: 20px; 
	margin: 0;
	text-transform: lowercase;
}

.count-dot {
	padding: 0 5px;
}

.regnow-title p {
	color: #000;
	font-size: 20px;
	font-weight: 400;
	line-height: normal; 
	margin: 0 0 23px;
}

.regnow-title p span {
	color: #EE3268;
	font-weight: 600;
}

.reg-input input {
	font-family: 'Inter', sans-serif;
	color: #EE3268;
	font-size: 14px;
	font-weight: 400;
	line-height: 28px;
	width: 100%;
	padding: 10px 15px;
	border-radius: 20px;
	border: 1px solid #000;
	background: #FFF; 
	outline: 0;
	text-transform: lowercase;
}

.reg-input input::placeholder {
	opacity: 1;
}

.reg-submit {
	color: #000;
	font-size: 24px;
	font-weight: 700;
	line-height: normal; 
	border: 0;
	font-family: 'Inter', sans-serif;
	padding: 7px 0px;
	text-align: center;
	border-radius: 25px;
	background: #01E3D3; 
	transition: 0.2s;
	outline: 0;
	cursor: pointer;
	min-width: 120px;
}

.reg-submit:hover {
	opacity: 0.7;
}

/* HOME OWNERSHIP AREA START  */

.home-ownership-area {
	background-image: url('../../public/images/home-ownership-bg.svg');
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	padding: 93px 0 14px;
	z-index: 99;
}

.home-ownership-title h2 {
	font-family: 'Inter', sans-serif;
	color: #000;
	font-size: 48px;
	font-weight: 700;
	line-height: normal; 
	margin: 0;
}

.home-ownership-title {
	padding: 0 0 45px;
}

.home-ownership-inner {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	position: relative;
}

.home-ownership-box {
	border-radius: 25px;
	padding: 33px 24px;
	width: 620px;
	background-image: url('../../public/images/box-bg.svg');
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	margin-bottom: 100px;
}

.home-ownership-box2 {
	background-image: url('../../public/images/box-bg2.svg');
}

.home-ownership-box3 {
	background-image: url('../../public/images/box-3.svg');
	padding: 40px 25px;
}

.home-ownership-box4 {
	text-align: end;
	background-image: url('../../public/images/box-4.svg');
}

.home-ownership-box5 {
	background-image: url('../../public/images/box-5.svg');
	margin-bottom: 55px;
}

.home-ownership-box6 {
	background-image: url('../../public/images/box-6.svg');
	margin-bottom: 55px;
}

.home-ownership-box p {
	font-family: 'Inter', sans-serif;
	color: #000;
	font-size: 32px;
	font-weight: 400;
	line-height: normal; 
	margin: 0;
}

.happy-img {
	position: absolute;
	left: 50%;
	transform: translate(-50%);
}

.footer-inner {
	background: rgba(159, 255, 248, 0.25);
	padding: 23px 0 10px;
}

.footer-nav .nav-items ul {
	justify-content: center;
}

.footer-btm ul {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	padding-top: 15px;
}

.footer-btm ul li {
	font-family: 'Inter', sans-serif;
	color: #000;
	font-size: 18px;
	font-weight: 400;
	line-height: normal; 
	padding-left: 5px;
}

.footer-btm ul li span {
	font-weight: 600;
}

.footer-btm ul li a {
	color: #000;
	transition: 0.2s;
}

.footer-btm ul li a:hover {
	opacity: 0.8;
}

.banner-right-sm {
	display: none;
	padding: 0 0 30px;
}

.happy-img-sm {
	display: none;
	padding: 0 0 20px;
}

/*============ SERVICE PEOVIDER PAGE START================== */

.service-hero {
	padding: 60px 0 0;
}

.service-hero .hero-inner {
	position: relative;
}

.banner-right-s {
	position: absolute;
	bottom: -15px;
	right: 0;
}

.service-hero-cnt {
	padding-top: 0px;
	padding-left: 30px;
}

.shc-title h2 {
	font-family: 'Inter', sans-serif;
	color: #000;
	font-size: 35px;
	font-weight: 600;
	line-height: normal; 
	margin: 0 0 38px;
}


.shc-checkbox input[type="checkbox"] {
	accent-color: #EE3268;
}

.shc-checkbox {
	padding-bottom: 22px;
}

.shc-checkbox label {
	font-family: 'Inter', sans-serif;
	color: #000;
	font-size: 20px;
	font-weight: 400;
	line-height: normal; 
	display: inline-block;
	padding-left: 5px;
}

.shc-checkbox label span {
	font-weight: 400 !important;
}

.right-plan-title {
	background: #9FFFF8; 
	padding: 25px 0;
}

.plan-title h2 {
	font-family: 'Inter', sans-serif;
	text-align: center;
	color: #000;
	font-size: 36px;
	font-weight: 600;
	line-height: normal; 
	margin: 0;
}

.plan-area {
	background-image: url('../../public/images/plan-area-bg.png');
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	padding: 63px 0 0;
}

.plan-inner {
	display: grid;
	grid-template-columns: repeat(3,1fr);
	grid-gap: 108px;
}

.plan-cnt-box {
	border-radius: 10px;
	border: 5px solid #EE3268;
	background: #FFF; 
	padding: 23px 0;
	min-height: 600px;
}

.plan-cnt-box2 {
	border: 5px solid #01E3D3; 
}

.plancnt-title h3 {
	font-family: 'Inter', sans-serif;
	color: #000;
	text-align: center;
	font-size: 36px;
	font-weight: 600;
	line-height: normal; 
	margin: 20px 0;
}

.plan-price h2 {
	font-family: 'Inter', sans-serif;
	color: #EE3268;
	font-size: 54px;
	font-weight: 400;
	line-height: normal; 
	margin: 0;
}

.plan-price p {
	font-family: 'Inter', sans-serif;
	color: #000;
	font-size: 24px;
	font-weight: 400;
	line-height: normal; 
	margin: 0;
}

.plan-price span {
	font-family: 'Inter', sans-serif;
	display: block;
	color: #01E3D3;
	font-size: 36px;
	font-weight: 600;
	line-height: normal; 
	padding: 12px 0 17px;
}

.plan-cnt-btm {
	padding: 45px 0 0;
}

.plan-cnt-btm p {
	font-family: 'Inter', sans-serif;
	color: #000;
	font-size: 20px;
	font-weight: 400;
	line-height: normal; 
	margin: 0;
}

.plan-cnt-btm p span {
	font-weight: 400;
}

.plan-cnt-box3 {
	padding-top: 60px;
}

.plancnt-title p {
	font-family: 'Inter', sans-serif;
	color: #000;
	font-size: 30px;
	font-weight: 400;
	line-height: normal; 
	margin: 0;
}

.plan-btn {
	padding: 40px 17px 0;
}

.plan-btn a {
	display: block;
	border-radius: 25px;
	border: 5px solid #01E3D3;
	background: #FFF; 
	color: #EE3268;
	font-size: 30px;
	font-weight: 600;
	line-height: normal; 
	font-family: 'Inter', sans-serif;
	padding: 20px 20px;
	transition: 0.2s;
}

.plan-btn a:hover {
	opacity: 0.8;
}

.bookdemo-area {
	padding: 0 0 35px;
}

.bookdemo-cnt {
	border-radius: 10px;
	border: 5px solid #EE3268;
	background: #faccd9;
	padding: 20px 25px;
	margin-top: 50px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
}

.bdc-left h3 {
	font-family: 'Inter', sans-serif;
	color: #000;
	font-size: 36px;
	font-style: normal;
	font-weight: 600;
	line-height: normal; 
	margin: 0;
}

.bdc-left p {
	font-family: 'Inter', sans-serif;
	color: #000;
	font-size: 24px;
	font-weight: 500;
	line-height: normal; 
	margin: 0;
}

.bdc-btn {
	padding-right: 30px;
}

.bdc-btn a {
	display: inline-block;
	transition: 0.2s;
	color: #EE3268;
	font-size: 30px;
	font-weight: 600;
	line-height: normal; 
	border-radius: 10px;
	border: 2px solid #000;
	background: #FFF; 
	padding: 10px 34px;
}

.bdc-btn a:hover {
	opacity: 0.8;
}

.color-b {
	color: #000;
	font-weight: 600;
}

.choose-area {
	background-image: url('../../public/images/choose-bg.png');
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	padding: 85px 0 108px;
}

.choose-option-cnt {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	justify-content: space-between;
	padding: 0 0 17px;
}

.choose-right {
	max-width: 700px;
}

.choose-option-cnt p {
	font-family: 'Inter', sans-serif;
	color: #EE3268;
	font-size: 20px;
	font-weight: 600;
	line-height: normal; 
	margin: 0;
}

.choose-option-cnt select {
	width: 310px;
	padding: 15px 10px;
	border-radius: 4px;
	border: 1px solid #F0F3FA;
	background:#FFF; 
	appearance: none;
	outline: 0;
	background-image: url('../../public/images/arrow.svg');
	background-position: 94% 53%;
	background-repeat: no-repeat;
	cursor: pointer;
}

.choose-right-checkbox label {
	font-weight: 500;
}

.payment-btn {
	padding: 20px 0 0;
}

.payment-btn a {
	font-family: 'Inter', sans-serif;
	border-radius: 10px;
	border: 2px solid #000;
	background: #EE3268; 
	display: inline-block;
	color: #FFF;
	font-size: 30px;
	font-weight: 600;
	line-height: normal; 
	padding: 12px 30px;
	transition: 0.2s;
}

.payment-btn a:hover {
	opacity: 0.8;
}

.choose-left {
	padding: 150px 0 0;
}

.choose-inner {
	position: relative;
}

.choose-right-img {
	position: absolute;
	right: 0;
	bottom: 0;
}

.choose-right-img img {
	max-width: 335px;
	rotate: -360deg;
}

.footer2 {
	margin-bottom: 17px;
}

/*================== INVEST PAGE START==================== */

.invest-hero-title h2 {
	font-family: 'Inter', sans-serif;
	color: #000;
	font-size: 30px;
	font-weight: 500;
	line-height: normal; 
	margin: 0 0 30px;
}

.invest-hero-title h2 span {
	color: #01E3D3;
}

.invest-hero-left .shc-checkbox {
	padding-bottom: 17px;
}

.invest-hero-left .shc-checkbox-area {
	padding-bottom: 70px;
}

.invest-form {
	max-width: 675px;
}

.invest-form input {
	border-radius: 10px;
	margin-bottom: 30px;
}

.invest-form .reg-submit input {
	border-radius: 15px;
	border: 2px solid #000;
	background: #EE3268; 
	padding: 13px 36px;
	color: #fff;
}

.invest-hero .hero-inner {
	padding: 0 0 25px;
}

.invest-hero-right {
	padding: 30px 0 0;
}

/*=================== BLOGS PAGE START================= */

.blogs-hero {
	padding: 78px 0 20px;
}

.blog-hero-title h2 {
	font-family: 'Inter', sans-serif;
	color: #000;
	font-size: 30px;
	font-weight: 500;
	line-height: normal; 
	margin: 0 0 50px;
}

.blog-hero-title h2 span {
	font-weight: 500 !important;
}

.blog-hero-title p {
	font-family: 'Inter', sans-serif;
	text-align: end;
	color: #000;
	font-size: 24px;
	font-weight: 500;
	line-height: normal; 
	margin: 0 0 7px;
}

.blog-hero-title p span {
	color: #EE3268;
}

.blogs-wrapper {
	display: grid;
	grid-template-columns: repeat(3,1fr);
	grid-gap: 60px 90px;
}

.blogs-content {
	border-radius: 10px;
	border: 2px solid #EE3268;
	background: #FFF; 
	padding: 20px 20px;
}

.blogs-content2 {
	border: 2px solid #01E3D3; 
}

.blog-img img {
	width: 100%;
	border-radius: 10px;
	border: 1px solid #000; 
	transition: 0.2s;
}

.blog-img img:hover {
	opacity: 0.8;
}

.blog-cnt-title h2 {
	font-family: 'Inter', sans-serif;
	color: #EE3268;
	font-size: 30px;
	font-weight: 500;
	line-height: normal; 
	margin: 30px 0 0;
}

.blog-cnt-title h2 a {
	color: #EE3268;
	transition: 0.2s;
}

.blog-cnt-title h2 a:hover {
	opacity: 0.8;
}

.blog-cnt-title p {
	font-family: 'Inter', sans-serif;
	color: #000;
	font-size: 20px;
	font-weight: 500;
	line-height: normal; 
	margin: 20px 0 0;
}

.blog-cnt-title p span {
	color: #EE3268; 
}

.blog-cnt-title p:last-child {
	margin-top: 35px;
}

.next-btn {
	padding: 30px 0 0;
}

.next-btn a {
	font-family: 'Inter', sans-serif;
	transition: 0.2s;
	display: inline-block;
	color: #000;
	font-size: 24px;
	font-weight: 500;
	line-height: normal; 
}

.next-btn a:hover {
	color: #EE3268;
}

.blog-header {
	background-image: url('../../public/images/blog-bg.png');
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.invest-hero-right-sm {
	display: none;
}

.main-content {
    position: relative;
}

.main-content .owl-theme .custom-nav {
    position: absolute;
    top: 20%;
    left: 0;
    right: 0;
	display: none;
}

.slider-img {
	text-align: end;
	margin-left: auto;
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;
}

.phone-img img {
	max-width: 300px;
	margin-left: auto;
}

.main-content  .owl-theme .owl-dots .owl-dot {
	display: none;
}

.couple-img {
	margin-right: -50px;
	z-index: 9;
}

.plan-row {
	min-height: 140px;
}

.plan-price1 {
	font-weight: 100;
	font-size: 26px;
  font-family: inter;
}

.plan-price2 {
	font-weight: 100;
	font-size: 24px;
  font-family: inter;
}

.plan-col2 {
	background-color: #ffe0e9;
}

.plan-col3 {
	background-color: #ddfffd;
}

.plan-col-middle {
	background-color: #f7d8e1;
}

.plan-col-m1 {
	background-color: #f0f0f0;
}

.plan-col-m3 {
	background-color: #d4f5f3;
}

.plan-col-last {
	background-color: #f4e8ff;
}

.plan-last-middle {
	background-color: #f9d4e9;
}

.plan-last {
	color: #EE3268;
}

.plan-last-end {
	background-color: #d6f0fc;
}

.line-through {
	position: absolute;
	bottom: 10px;
	left: 12px;
}

.line-through2 {
	position: absolute;
	bottom: 10px;
	left: 8px;
}

.line-through3 {
	position: absolute;
	bottom: 10px;
	left: 2px;
}

.limited-time-img {
	width: 290px;
	height: 290px;
	position: absolute;
	right: -77px;
	top: -111px;
}