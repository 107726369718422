@media screen and (min-width: 1401px) and (max-width: 1600px) {
    
    .home-ownership-box p {
        font-size: 30px;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1400px) {

    .home-ownership-box p {
        font-size: 24px;
    }

    .logo img {
        max-width: 200px;
    }

    .hero-area {
        padding: 80px 0;
    }

    .countdown-area {
        padding: 45px 0 100px;
    }

    .header-area {
        background-size: cover;
    }

    .home-ownership-area {
        padding: 60px 0 14px;
    }

    .home-ownership-title h2 {
        font-size: 42px;
    }

    .home-ownership-box {
        padding: 33px 24px;
        width: initial;
        width: 40%;
        margin-bottom: 100px;
    }

    .blogs-wrapper {
        grid-template-columns: repeat(3,1fr);
        grid-gap: 40px 40px;
    }

    .blog-cnt-title h2 {
        font-size: 27px;
    }

    .blog-cnt-title p {
        font-size: 19px;
    }

    .shc-checkbox label {
        font-size: 18px;
    }

    .banner-right-s img {
        max-width: 250px;
    }

    .service-hero {
        padding-bottom: 0;
    }

    .plan-inner {
        grid-gap: 38px;
    }

    .bookdemo-cnt {
        flex-wrap: nowrap;
    }

    .bdc-left  {
        width: 70%;
    }

    .bdc-btn {
        width: 30%;
        padding-right: 0;
        text-align: end;
    }

    .phone-img img {
        max-width: 260px;
    }

}

/* FOR-LG-START */
@media screen and (min-width: 992px) and (max-width: 1199px) {

    .logo img {
        max-width: 180px;
    }

    .nav-items ul li {
        padding-right: 40px;
    }

    .hero-title h2 {
        font-size: 28px;
    }

    .header-area {
        background-size: cover;
    }

    .countdown-title h3 {
        font-size: 38px;
    }

    .countdown-box h3 {
        font-size: 42px;
    }

    .hero-area {
        padding: 60px 0;
    }

    .countdown-area {
        padding: 50px 0 60px;
    }

    .home-ownership-title h2 {
        font-size: 38px;
    }

    .home-ownership-area {
        padding: 50px 0 14px;
    }

    .home-ownership-box p {
        font-size: 20px;
    }

    .home-ownership-box {
        padding: 33px 24px;
        width: initial;
        width: 40%;
        margin-bottom: 100px;
    }

    .blogs-wrapper {
        grid-template-columns: repeat(3,1fr);
        grid-gap: 20px 20px;
    }

    .blog-cnt-title h2 {
        font-size: 23px;
    }

    .blog-cnt-title p {
        font-size: 17px;
    }

    .blogs-content {
        padding: 15px 15px;
    }

    .blog-hero-title p {
        font-size: 20px;
    }

    .next-btn a {
        font-size: 22px;
    }

    .choose-right .choose-right-checkbox .shc-checkbox label {
        font-size: 17px;
    }

    .choose-right-img img {
        max-width: 279px;
    }

    .payment-btn a {
        font-size: 26px;
    }

    .choose-area {
        padding: 50px 0 60px;
    }

    .bookdemo-cnt {
        padding: 20px 25px;
        flex-wrap: nowrap;
    }

    .bdc-left  {
        width: 70%;
    }

    .bdc-btn {
        width: 30%;
        padding-right: 0;
        text-align: end;
    }

    .bdc-left h3 {
        font-size: 30px;
        margin: 0 0 10px;
    }

    .bdc-left p {
        font-size: 20px;
    }

    .bdc-btn a {
        font-size: 26px;
    }

    .plan-inner {
        grid-gap: 20px;
    }

    .plancnt-title h3 {
        font-size: 30px;
    }

    .plan-price h2 {
        font-size: 40px;
    }

    .plan-price p {
        font-size: 18px;
    }

    .plancnt-title p {
        font-size: 26px;
    }

    .plan-btn a {
        font-size: 26px;
    }

    .plan-cnt-box {
        min-height: 530px;
    }

    .shc-title h2 {
        font-size: 30px;
    }

    .shc-checkbox label {
        font-size: 16px;
    }

    .banner-right-s img {
        max-width: 180px;
    }

    .service-hero {
        padding-bottom: 0;
    }

    .invest-hero-left .shc-checkbox-area {
        padding-bottom: 20px;
    }

    .phone-img img {
        max-width: 227px;
    }

} 

/*FOR-TABLATE-START*/
@media screen and (min-width: 768px) and (max-width: 991px) {

    .menu-bar {
        display: block;
    }

    .logo img {
        max-width: 150px;
    }

    .nav-items {
        display: none;
    }

    .hero-title h2 {
        font-size: 26px;
    }

    .header-area {
        background-size: cover;
    }

    .countdown-title h3 {
        font-size: 32px;
    }

    .countdown-box h3 {
        font-size: 35px;
    }

    .countdown-box h4 {
        font-size: 20px;
    }

    .countdown-box {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 80px;
        height: 80px;
    }

    .countdown-area {
        padding: 60px 0 50px;
    }

    .hero-area {
        padding: 70px 0;
    }

    .regnow-title p {
        font-size: 18px;
    }

    .home-ownership-title h2 {
        font-size: 36px;
    }

    .home-ownership-box p {
        font-size: 20px;
    }

    .home-ownership-box {
        padding: 33px 24px;
        width: initial;
        width: 40%;
        margin-bottom: 30px;
    }

    .home-ownership-box p br {
        display: none;
    }

    .home-ownership-area {
        padding: 30px 0 14px;
    }

    .footer-nav .nav-items {
        display: block;
    }

    .footer-nav .nav-items ul li {
        padding: 0 15px;
    }

    .blogs-wrapper {
        grid-template-columns: repeat(2,1fr);
        grid-gap: 20px 20px;
    }

    .blog-cnt-title h2 {
        font-size: 23px;
    }

    .blog-cnt-title p {
        font-size: 17px;
    }

    .blogs-content {
        padding: 15px 15px;
    }

    .blog-hero-title p {
        font-size: 20px;
    }

    .next-btn a {
        font-size: 22px;
    }

    .service-hero-cnt {
        padding-top: 20px;
        padding-left: 0;
    }

    .shc-title h2 {
        font-size: 26px;
        margin: 0 0 30px;
    }

    .shc-checkbox {
        display: flex;
        align-items: flex-start;
    }

    .shc-checkbox label {
        font-size: 17px;
        padding-left: 10px;
        margin-top: -5px;
    }

    .banner-right-s img {
        max-width: 180px;
    }

    .service-hero {
        padding-bottom: 0;
    }

    .plan-title h2 {
        font-size: 30px;
    }

    .plan-inner {
        grid-template-columns: repeat(2,1fr);
        grid-gap: 30px;
    }

    .plancnt-title h3 {
        font-size: 32px;
    }

    .plan-price h2 {
        font-size: 46px;
    }

    .plan-price p {
        font-size: 19px;
    }

    .plan-price span {
        font-size: 32px;
    }

    .plan-cnt-btm p {
        font-size: 19px;
    }

    .plancnt-title p {
        font-size: 24px;
    }

    .plan-btn a {
        font-size: 26px;
    }

    .plan-cnt-box {
        min-height: 547px;
    }

    .bdc-left h3 {
        font-size: 30px;
    }

    .bdc-left p {
        font-size: 22px;
        margin: 15px 0;
    }

    .bdc-btn a {
        font-size: 24px;
    }

    .choose-left {
        padding: 0 0 30px;
        text-align: center;
    }

    .choose-area {
        padding: 50px 0 70px;
    }

    .choose-right-img img {
        max-width: 300px;
    }

    .invest-hero-title h2 {
        font-size: 22px;
        margin: 0 0 15px;
    }

    .invest-hero-left .shc-checkbox-area {
        padding-bottom: 20px;
    }

    .invest-form input {
        margin-bottom: 20px;
    }

    .invest-form .reg-submit input {
        padding: 10px 21px;
        font-size: 18px;
    }

    .happy-img img {
        max-width: 250px;
    }

}

/*FOR-MOBIL-START*/
@media screen and (max-width: 767px) {

	.menu-bar {
        display: block;
    }

    .logo img {
        max-width: 130px;
    }

    .nav-items {
        display: none;
    }

    .hero-area {
        padding: 40px 0;
    }

    .hero-title h2 {
        font-size: 25px;
    }

    .countdown-area {
        padding: 40px 0;
    }

    .countdown-title h3 {
        font-size: 25px;
    }

    .countdown-box h3 {
        font-size: 32px;
    }


    .countdown-box h4 {
        font-size: 20px;
    }

    .countdown-box {
        height: 85px;
        width: 85px;
    }

    .regnow-title p {
        font-size: 18px;
    }

    .reg-input {
        padding-bottom: 20px;
    }

    .phone-img img {
        max-width: 300px;
        margin: 0 auto;
    }

    .slider-img {
        padding-bottom: 40px;
        justify-content: center;
    }

    .index-hero-inner .col-lg-8 {
        order: 2;
    }

    .index-hero-inner .col-lg-4 {
        order: 1;
    }

    .home-ownership-area {
        padding: 0 0 14px;
    }

    .home-ownership-title h2 {
        font-size: 25px;
    }

    .happy-img {
        display: none;
    }

    .home-ownership-box p {
        font-size: 20px;
    }

    .home-ownership-box {
        padding: 20px 20px;
        margin-bottom: 20px;
    }

    .happy-img-sm {
        display: block;
    }

    .home-ownership-title {
        padding: 0 0 20px;
    }

    .reg-submit input {
        font-size: 18px;
    }

    .reg-submit {
        text-align: center;
    }

    .nav-items ul li a {
        font-size: 18px;
    }

    .footer-nav .nav-items {
        display: block;
    }

    .footer-nav .nav-items ul li {
        padding: 0 6px;
    }

    .footer-nav .nav-items ul li a {
        font-size: 15px;
    }

    .footer-btm ul li {
        font-size: 12px;
    }

    .blogs-wrapper {
        grid-template-columns: repeat(1,1fr);
        grid-gap: 20px 20px;
    }

    .blog-cnt-title h2 {
        font-size: 23px;
    }

    .blog-cnt-title p {
        font-size: 17px;
    }

    .blogs-content {
        padding: 15px 15px;
    }

    .blog-hero-title p {
        font-size: 17px;
    }

    .next-btn a {
        font-size: 20px;
    }

    .blog-hero-title h2 {
        font-size: 24px;
        margin: 0 0 15px;
    }

    .service-hero-cnt {
        padding-top: 20px;
        padding-left: 0;
    }

    .shc-title h2 {
        font-size: 25px;
        margin: 0 0 25px;
    }

    .shc-checkbox {
        display: flex;
        align-items: flex-start;
    }

    .banner-right-s {
        display: none;
    }

    .shc-checkbox label {
        font-size: 16px;
        padding-left: 10px;
        margin-top: -5px;
    }

    .banner-right-sm2 {
        padding-bottom: 0;
    }

    .service-hero {
        padding-bottom: 0;
    }

    .header-area {
        padding-bottom: 0;
    }

    .plan-title h2 {
        font-size: 25px;
    }

    .right-plan-title {
        padding: 20px 0;
    }

    .plan-inner {
        grid-template-columns: repeat(1,1fr);
        grid-gap: 25px;
    }

    .plan-area {
        padding: 40px 0 0;
    }

    .plancnt-title h3 {
        font-size: 32px;
    }

    .plan-price h2 {
        font-size: 42px;
    }

    .plan-price p {
        font-size: 17px;
    }

    .plan-cnt-box {
        min-height: 500px;
    }

    .plancnt-title p {
        font-size: 24px;
    }

    .plan-btn a {
        border-radius: 14px;
        font-size: 26px;
        padding: 15px 20px;
    }

    .bdc-left h3 {
        font-size: 23px;
    }

    .bdc-left p {
        font-size: 17px;
        margin: 15px 0;
    }

    .bookdemo-cnt {
        padding: 15px 15px;
    }

    .bdc-btn a {
        font-size: 20px;
        padding: 10px 20px;
    }

    .choose-left {
        padding: 0;
        text-align: center;
    }

    .choose-area {
        padding: 30px 0;
    }

    .choose-right {
        padding-top: 20px;
    }

    .choose-option-cnt p {
        font-size: 17px;
        margin: 0 0 10px;
    }

    .choose-option-cnt select {
        padding: 10px 10px;
    }

    .choose-right-img {
        display: none;
    }

    .payment-btn a {
        font-size: 20px;
        padding: 9px 20px;
    }

    .choose-right-checkbox {
        padding-top: 20px;
    }

    .plan-cnt-btm p {
        font-size: 17px;
    }

    .invest-hero-right {
        display: none;
    }

    .invest-hero-right-sm {
        display: block;
        padding-bottom: 40px;
    }

    .invest-hero-title h2 {
        font-size: 23px;
    }

    .invest-hero-left .shc-checkbox-area {
        padding-bottom: 15px;
    }

    .invest-form input {
        margin-bottom: 0px;
    }




}




		
		         
